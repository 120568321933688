:root {
  --font-header: creepster, cursive;
  --font-body: montserrat, sans-serif;
  --font-mainheaders: poller one, cursive;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.container {
  padding: 0 1.5rem;
  max-width: 69.375rem;
  margin: 0 auto;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
    transform: translate(0, 0);
  }
}

.primary-header {
  display: flex;
  justify-content: space-between;
  background-color: rgba(173, 216, 230, 0.863);
  width: 100%;
}
.primary-header__logo {
  margin: 0.625rem;
  width: 14.375rem;
  height: auto;
}

.primary-navigation {
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (max-width: 39.9375em) {
  .primary-navigation {
    --underline-gap: .5rem;
    position: fixed;
    z-index: 1000;
    inset: 0 0 0 30%;
    list-style: none;
    padding: min(20rem, 15vh) 2rem;
    transform: translateX(100%);
    transition: transform 500ms ease-in-out;
  }
}

.primary-navigation[data-visible=true] {
  transform: translateX(0);
}

@supports (backdrop-filter: blur(5px)) {
  .primary-navigation {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
}
.nav-links {
  text-transform: uppercase;
  list-style-type: none;
  height: 100%;
}
.nav-links__li {
  margin-bottom: 1.875rem;
  letter-spacing: 0.16875rem;
  line-height: 1.1875rem;
  border-bottom: 0.2rem solid var(--clr-whiteOP0);
}
.nav-links__li:hover, .nav-links :focus {
  border-color: var(--clr-white0P5);
}
.nav-links__a {
  text-decoration: none;
  color: var(--clr-white) !important;
}
.nav-links .number {
  font-weight: bold;
  line-height: 1.1875rem;
  margin-right: 0.5rem;
}

.navbar-toggler {
  border: 0 !important;
  z-index: 2000;
  margin-right: 0.9375rem;
  background-position: center;
}

.navbar-toggle-icon {
  background-image: url("/images/icon-hamburger.svg") !important;
  display: inline-block;
  width: 1.5rem;
  height: 1.3125rem;
}

.navbar-toggle-icon.open {
  background-image: url("/images/icon-close.svg") !important;
  background-repeat: no-repeat;
  display: inline-block;
  width: 1.5rem;
  height: 1.3125rem;
  background-position: center;
}

.socials {
  margin-top: 0.625rem;
  text-align: center;
  align-items: center;
}

.content {
  text-align: center;
  font-family: var(--font-mainheaders);
}
.content__header {
  font-weight: 700;
}
.content__text {
  font-family: var(--font-body);
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
}
.content .g {
  color: #7cbb1d;
  font-family: var(--font-header);
  letter-spacing: 0.0625rem;
}

.p {
  color: #800080;
  font-family: var(--font-header);
}

.small-rem {
  font-family: var(--font-body);
  color: #000000;
  display: flex;
  text-align: center;
  letter-spacing: 0.0625rem;
  margin-bottom: 0.625rem;
}

.socials {
  font-family: var(--font-mainheaders);
}

.form-head {
  font-family: var(--font-mainheaders);
}

.pp {
  width: 100%;
  height: auto;
}

form {
  padding: 0 0.3125rem 0 0.3125rem;
}

.input {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1.5rem;
}
.input + .input {
  margin-top: 1.5rem;
}

.input-label {
  color: #8597a3;
  position: absolute;
  top: 1.5rem;
  transition: 0.25s ease;
}

.input-field {
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 1.125rem;
  padding: 0.25rem 0;
}
.input-field:focus, .input-field:valid {
  outline: 0;
  border-bottom-color: #6658d3;
}
.input-field:focus + .input-label, .input-field:valid + .input-label {
  color: #6658d3;
  transform: translateY(-1.5rem);
}

.action {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  margin-bottom: 3.125rem;
}

.action-button {
  font: inherit;
  font-size: 1.25rem;
  padding: 1em;
  width: 18.75rem;
  font-weight: 500;
  background-color: #6658d3;
  border-radius: 6px;
  color: #FFF;
  border: 0;
}
.action-button:focus {
  outline: 0;
}

.footer-section {
  background-color: rgba(173, 216, 230, 0.863);
}
.footer-section .social-links {
  list-style: none;
}