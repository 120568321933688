@use '../util' as *;


.primary-header {
    display: flex;
    justify-content: space-between;
    background-color: rgba(173, 216, 230, 0.863);
    width: 100%;

    &__logo {
        margin: rem(10);
        width: rem(230);
        height: auto;
    }
}
    // Nav styles 
    .primary-navigation {
            list-style: none;
            padding: 0;
            margin: 0;
            @include breakpoint-down(small) {
                --underline-gap: .5rem;
                position: fixed;
                z-index: 1000;
                inset: 0 0 0 30%;
                list-style: none;
                padding: min(20rem, 15vh) 2rem;
                transform: translateX(100%);
                transition: transform 500ms ease-in-out;
            }
    }
        
    .primary-navigation[data-visible="true"] {
            transform: translateX(0);
    }
        
        
    @supports (backdrop-filter: blur( 5px )) {
            .primary-navigation {
                background: rgba(255, 255, 255, 0.15);
                box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
                backdrop-filter: blur( 5px );
                -webkit-backdrop-filter: blur( 5px );
                border: 1px solid rgba( 255, 255, 255, 0.18 );
            }
    }

// links styles
.nav-links{
    text-transform: uppercase;
    list-style-type: none;
    height: 100%;
 
    &__li {
        margin-bottom: rem(30);
        letter-spacing: rem(2.7);
        line-height: rem(19);
        border-bottom: .2rem solid var(--clr-whiteOP0);
    }

    &__li:hover,:focus {
        border-color: var(--clr-white0P5);
    }

    &__a {
        text-decoration: none;
        color: var(--clr-white) !important;
    }

    .number {
        font-weight: bold;
        line-height: rem(19);
        margin-right: rem(8);
    }
}

// Nav transitions 
.navbar-toggler {
    border: 0 !important;
    z-index: 2000;
    margin-right: rem(15);
    background-position: center;
}


.navbar-toggle-icon {
    background-image: url("/images/icon-hamburger.svg") !important;
    display: inline-block;
    width: rem(24);
    height: rem(21);
}


.navbar-toggle-icon.open {
    background-image: url("/images/icon-close.svg") !important;
    background-repeat: no-repeat;
    display: inline-block;
    width: rem(24);
    height: rem(21);
    background-position: center;
}

.socials {
    margin-top: rem(10);
    text-align: center;
    align-items: center;
}

// Websites text styles 
.content {
    text-align: center;
    font-family: var(--font-mainheaders);
    
    &__header {
        font-weight: 700;
    }

    &__text {
        font-family: var(--font-body);
        font-size: rem(18);
        letter-spacing: rem(2);
    }

    .g {
        color: #7cbb1d;
        font-family: var(--font-header);
        letter-spacing: rem(1);
    }

}

.p {
    color: #800080;
    font-family: var(--font-header);
}

.small-rem {
    font-family: var(--font-body);
    color: #000000;
    display: flex;
    text-align: center;
    letter-spacing: rem(1);
    margin-bottom: rem(10);
}

.socials {
    font-family: var(--font-mainheaders);
}

.form-head {
    font-family: var(--font-mainheaders);
}

// paypal image
.pp {
    width: 100%;
    height: auto;
}

// form styles
form {
    padding: 0 rem(5) 0 rem(5);
} 

.input {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
	padding-top: 1.5rem;
	&+.input {
		margin-top: 1.5rem;
	}
}

.input-label {
	color: #8597a3;
	position: absolute;
	top: 1.5rem;
	transition: .25s ease;
}

.input-field {
	border: 0;
	z-index: 1;
	background-color: transparent;
	border-bottom: 2px solid #eee; 
	font: inherit;
	font-size: 1.125rem;
	padding: .25rem 0;
	&:focus, &:valid {
		outline: 0;
		border-bottom-color: #6658d3;
		&+.input-label {
			color: #6658d3;
			transform: translateY(-1.5rem);
		}
	}
}

.action {
	margin-top: 2rem;
    display: flex;
    justify-content: center;
    margin-bottom: rem(50);
}

.action-button {
	font: inherit;
	font-size: 1.25rem;
	padding: 1em;
    width: rem(300);
	font-weight: 500;
	background-color: #6658d3;
	border-radius: 6px;
	color: #FFF;
	border: 0;
	&:focus {
		outline: 0;
	}
}


// footer styles
.footer-section {
    background-color: rgba(173, 216, 230, 0.863);

    .social-links {
        list-style: none;
    }
}







